import { upperCase } from 'lodash';
import { useState } from 'react';
import {
  FaCompactDisc,
  FaExclamationTriangle,
  FaPauseCircle,
  FaPlayCircle,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../../../api';
import { IFile } from '../../../../api/services/file.service';
import { ITrack } from '../../../../api/services/track.service';
import { useAuth } from '../../../../hooks/useAuth';
import { usePlayer } from '../../../../hooks/usePlayer';
import FileListOptionMenu from './FileListOptionMenu';

export default function TrackListRow({
  track,
  file,
  onSelected,
  onRename,
  onDelete,
  downloadFile,
  showDelete,
  deleteOptionLabel = 'Delete',
  isActive,
}: {
  track: ITrack;
  file: (IFile & { progress?: number }) | null;
  onRename: () => void;
  onSelected?: (id: string) => void;
  onDelete: () => void;
  downloadFile: (file: IFile) => void;
  showDelete: boolean;
  deleteOptionLabel?: string;
  isActive: boolean;
}) {
  const { activeTeam } = useAuth();

  const { setUrl, setFileId, fileId, isPlaying, togglePlay, setTrack } =
    usePlayer();

  const [isLoadingUrl, setIsLoadingUrl] = useState(false);

  const onClickPlay = () => {
    if (!file) return;
    setIsLoadingUrl(true);
    api.file
      .getFilePresignedDownloadUrl(file.id)
      .then((res) => {
        setUrl(res.data.result.presignedUrl);
        setFileId(file.id);
        setTrack(track);
      })
      .catch(() => {
        toast.error("Can't get link for artwork");
      })
      .finally(() => {
        setIsLoadingUrl(false);
      });
  };

  return (
    <div
      key={track.id}
      className={`flex items-center justify-between border-b border-gray-200 px-4 py-4 transition-colors ease-in-out hover:cursor-pointer hover:bg-blue-200 dark:border-gray-600 dark:hover:bg-slate-800 ${
        isActive ? 'bg-gray-100 dark:bg-gray-800' : ''
      }`}
      onClick={() => {
        if (onSelected) onSelected(track.id);
      }}
    >
      <div className='flex w-full'>
        <div className='flex w-full flex-row items-center justify-between space-x-4'>
          <div className='flex grow items-center space-x-2'>
            <div>
              <FaCompactDisc
                size={40}
                className='text-gray-600 dark:text-gray-300'
              />
            </div>
            <div className='flex w-full max-w-[410px] flex-col justify-center'>
              {/* This case should no happen */}
              {!file && (
                <>
                  <div className='mb-1 flex w-full justify-between'>
                    <span className='break-all text-sm font-semibold'>
                      {track.name}
                    </span>
                  </div>

                  <div className='w-full'>
                    <div className='flex items-center space-x-2 text-amber-600'>
                      <FaExclamationTriangle />
                      <div>Raw file missing</div>
                    </div>
                  </div>
                </>
              )}
              {file && (
                <>
                  <div className='flex w-full justify-between'>
                    <span className='break-all text-sm font-semibold'>
                      {track.name}
                    </span>
                    <span className='text-sm font-medium'>
                      {file.progress !== undefined &&
                        file.progress > 1 &&
                        `${file.progress}%`}
                    </span>
                  </div>
                  {(activeTeam?.teamMembershipDTO?.role === 'OWNER' ||
                    activeTeam?.teamMembershipDTO?.role === 'MEMBER') && (
                    <div className='max-w-[90px]'>
                      <div
                        className={`inline-block min-w-[66px] rounded px-1 text-center text-xs font-semibold   ${
                          track?.metadata.masterStatus === 'mastered'
                            ? 'bg-green-200 text-green-800'
                            : 'bg-indigo-600 text-white'
                        }`}
                      >
                        {upperCase(track?.metadata.masterStatus || 'draft')}
                      </div>
                    </div>
                  )}
                  <div className='w-full flex-col justify-end break-all text-sm font-semibold text-gray-400'>
                    {file.progress ? (
                      <div className='h-2.5 w-full rounded-full bg-gray-700 dark:bg-gray-300'>
                        <div
                          className='h-2.5 rounded-full bg-emerald-600'
                          style={{
                            width: `${file.progress + 1}%`,
                          }}
                        ></div>
                      </div>
                    ) : (
                      track.metadata.versionName && (
                        <div className='flex w-full space-x-1'>
                          <div className='break-all text-xs'>
                            Version Name: {track.metadata.versionName}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='mr-2 flex flex-col space-y-2 lg:flex-row lg:items-center lg:space-y-0 lg:space-x-2'>
            <button
              onClick={
                isPlaying && fileId === file?.id ? togglePlay : onClickPlay
              }
              className='cursor-pointer disabled:opacity-50'
              disabled={isLoadingUrl}
            >
              {isPlaying && fileId === file?.id ? (
                <FaPauseCircle className='h-6 w-6' />
              ) : (
                <FaPlayCircle className='h-6 w-6' />
              )}
            </button>
            {showDelete && (
              <FileListOptionMenu
                onRename={onRename}
                onDelete={onDelete}
                onDownload={() => {
                  if (file) {
                    downloadFile(file);
                  }
                }}
                deleteOptionLabel={deleteOptionLabel}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
