import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { capitalize } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../api';
import { IComposer } from '../api/services/composer.service';
import { IFile } from '../api/services/file.service';
import { IProject } from '../api/services/project.service';
import { ITag } from '../api/services/tag.service';
import { ITeamMembership } from '../api/services/team.service';
import { ITrack, TrackWithFiles } from '../api/services/track.service';
import { Card } from '../components/shared/Card';
import DeleteConfirmationDialog from '../components/shared/DeleteConfirmationDialog';
import Input from '../components/shared/Input';
import Loader from '../components/shared/Loader';
import Select from '../components/shared/Select';
import TextEditDialog from '../components/shared/TextEditDialog';
import { TagPicker } from '../components/studio/sections/trackInfo/TagPicker';
import TrackListRow from '../components/studio/sections/trackList/TrackListRow';
import { useAuth } from '../hooks/useAuth';
import { tagCategories, useTags } from '../hooks/useTags';
import { downloadFile } from '../utils/fileHelper';
import { Mapping } from '../types';
import axios from 'axios';

enum FiltersEnum {
  name = 'name',
  submission = 'submission',
  artist = 'artist',
  album = 'album',
  bpm = 'bpm',
  // albumStatus = 'album_status',
  keynote = 'keynote',
  scaleType = 'scale_type',
  catalogue = 'catalogue',
  composer = 'composer',
  tags = 'tags',
}

const PROJECT_STATUSES = [
  'DRAFT',
  'REQUESTED',
  'SUBMITTED',
  'DONE',
  'RELEASED',
  'UNRELEASED',
];

// const ALBUM_STATUSES = ['RELEASED', 'UNRELEASED'];
const KEYNOTES = [
  'A',
  'A#',
  'Ab',
  'Atonal',
  'B',
  'Bb',
  'C',
  'C#',
  'Cb',
  'D',
  'D#',
  'Db',
  'E',
  'Eb',
  'F',
  'F#',
  'G',
  'G#',
  'Gb',
];
const SCALE_TYPES = ['Major', 'Minor'];
const CATALOGUES = ['Soundtrack', 'Trailer Music', 'Trailer Music;Soundtrack'];

export const Tracks = () => {
  const { activeTeam } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [albums, setAlbums] = useState<IProject[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [projects, setProjects] = useState<IProject[]>([]);
  const [allTracks, setAllTracks] = useState<TrackWithFiles[]>([]);
  const [tracks, setTracks] = useState<TrackWithFiles[]>([]);
  const [artists, setArtists] = useState<ITeamMembership[]>([]);
  const [composers, setComposers] = useState<IComposer[]>([]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [objectToBeDeleted, setObjectToBeDeleted] = useState<
    ((IFile | ITrack) & { type: string }) | null
  >(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [objectToBeRenamed, setObjectToBeRenamed] = useState<
    ((IFile | ITrack) & { type: string }) | null
  >(null);

  const name = searchParams.get(FiltersEnum.name);
  const submission = searchParams.get(FiltersEnum.submission);
  const artist = searchParams.get(FiltersEnum.artist);
  const album = searchParams.get(FiltersEnum.album);
  const bpm = searchParams.get(FiltersEnum.bpm);
  // const albumStatus = searchParams.get(FiltersEnum.albumStatus);
  const keynote = searchParams.get(FiltersEnum.keynote);
  const scaleType = searchParams.get(FiltersEnum.scaleType);
  const catalogue = searchParams.get(FiltersEnum.catalogue);
  const composer = searchParams.get(FiltersEnum.composer);

  const {
    activeTagCategory,
    setActiveTagCategory,
    activeTags,
    allTags,
    setAllTags,
    setActiveTags,
  } = useTags();

  function handleTagSelect(tag: ITag) {
    if (activeTags.some((t) => t.id === tag.id)) {
      setActiveTags((p) => p.filter((t) => t.id !== tag.id));
    } else {
      setActiveTags((p) => [...p, tag]);
    }
  }

  useEffect(() => {
    if (activeTeam) {
      Promise.all([
        api.project.getProjects(),
        api.team.getTeamMembers(activeTeam.id),
        api.album.getAlbums(),
        // FIXME: Don't do this, it will load all tracks in the system
        api.track.searchTrack({ size: 10000 }),
        api.composer.getTenantComposers(activeTeam.id),
        api.tag.getTags(),
      ])
        .then(
          ([
            projectsRes,
            artistsRes,
            albumsRes,
            tracksRes,
            composersRes,
            tagsRes,
          ]) => {
            setProjects(projectsRes.data.result || []);
            const projectGuests = artistsRes.data.result.filter(
              (a) => a.role === 'GUEST'
            );
            setArtists(projectGuests);
            setAlbums(albumsRes.data.result);
            setAllTracks(tracksRes.data.result?.content || []);
            setComposers(composersRes.data.result || []);
            setAllTags(tagsRes.data.result || []);
          }
        )
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [activeTeam]);

  const projectsMap = useMemo(() => {
    return projects.reduce((acc: Mapping<IProject>, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [projects]);

  const artistsMap = useMemo(() => {
    return artists.reduce((acc: Mapping<ITeamMembership>, item) => {
      acc[item.userId] = item;
      return acc;
    }, {});
  }, [artists]);

  const composersMap = useMemo(() => {
    return composers.reduce((acc: Mapping<IComposer>, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [composers]);

  const albumsMap = useMemo(() => {
    return albums.reduce((acc: Mapping<IProject>, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [albums]);

  const resetFilters = () => {
    const p = new URLSearchParams('');
    setSearchParams(p);
  };

  const fetchTracks = useCallback(async () => {
    const conditions = [
      ...(name
        ? [
            {
              key: 'name',
              type: 'eq',
              value: name,
            },
          ]
        : []),
      ...(submission
        ? [
            {
              key: 'project.id',
              type: 'eq',
              value: submission,
            },
          ]
        : []),
      ...(artist
        ? [
            {
              key: 'user.id',
              type: 'eq',
              value: artist,
            },
          ]
        : []),
      ...(album
        ? [
            {
              key: 'album.id',
              type: 'eq',
              value: album,
            },
          ]
        : []),
      ...(bpm
        ? [
            {
              key: 'metadata.bpm',
              type: 'eq',
              value: bpm,
            },
          ]
        : []),
      // ...(albumStatus
      //   ? [
      //       {
      //         key: 'album.status',
      //         type: 'eq',
      //         value: albumStatus,
      //       },
      //     ]
      //   : []),
      ...(keynote
        ? [
            {
              key: 'metadata.key',
              type: 'eq',
              value: keynote,
            },
          ]
        : []),
      ...(scaleType
        ? [
            {
              key: 'metadata.scaleType',
              type: 'eq',
              value: scaleType,
            },
          ]
        : []),
      ...(catalogue
        ? [
            {
              key: 'metadata.catalogue',
              type: 'eq',
              value: catalogue,
            },
          ]
        : []),
      ...(composer
        ? [
            {
              key: 'composer.id',
              type: 'eq',
              value: composer,
            },
          ]
        : []),
      ...(!!activeTags.length
        ? activeTags.map((tag) => ({
            key: 'tag.id',
            type: 'eq',
            value: tag.id,
          }))
        : []),
    ];
    try {
      // FIXME: handle pagination
      const res = await api.track.searchTrack({
        condition: {
          type: 'and',
          conditions,
        },
        size: 10000,
      });
      setTracks(res.data.result?.content || []);
    } catch (error) {
      console.error(error);
    }
  }, [
    name,
    submission,
    artist,
    album,
    bpm,
    // albumStatus,
    keynote,
    scaleType,
    catalogue,
    composer,
    activeTags,
  ]);

  useEffect(() => {
    if (activeTeam) {
      fetchTracks();
    }
  }, [fetchTracks, activeTeam]);

  const BPMOPtions = useMemo(() => {
    return allTracks
      .map((track) => +(track.metadata?.bpm || 0))
      .filter((v) => !!v)
      .sort((a, b) => a - b)
      .filter((v, i, self) => self.indexOf(v) === i);
  }, [allTracks]);

  console.log('BPMOPtions', BPMOPtions);

  const curActivaTags = useMemo(
    () =>
      activeTags.filter((tag) => tag.type === activeTagCategory.backendName),
    [activeTagCategory, activeTags]
  );

  const curCategoryTags = useMemo(
    () => allTags.filter((tag) => tag.type === activeTagCategory.backendName),
    [activeTagCategory, allTags]
  );

  async function deleteItem(id: string, type: any) {
    try {
      if (type === 'track') {
        setIsDeleting(true);
        const response = await api.track.deleteTrack(id);
        if (response.status === 204) {
          await fetchTracks();
          toast.success('Track deleted successfully');
        }
        setIsDeleting(false);
      } else {
        toast.warn("You can't delete that");
      }
    } catch (e) {
      toast.error(
        'Something really went wrong, you might want to contact support!'
      );
    }
  }

  const onDeleteCancel = useCallback(() => {
    setIsDeleteDialogOpen(false);
    setObjectToBeDeleted(null);
  }, []);

  const onDeleteSubmit = useCallback(() => {
    setIsDeleteDialogOpen(false);
    if (objectToBeDeleted) {
      deleteItem(objectToBeDeleted?.id, objectToBeDeleted?.type);
      setObjectToBeDeleted(null);
      // TODO: also delete file??
    }
    // eslint-disable-next-line
  }, [objectToBeDeleted]);

  async function renameItem(id: string, type: any, props: { name: string }) {
    try {
      if (type === 'track') {
        const response = await api.track.updateTrackPartially(id, props);
        if (response.status === 200) {
          await fetchTracks();
          toast.success('Track renamed successfully');
        }
      } else {
        toast.warn("You can't rename that");
      }
    } catch (e) {
      if (
        type === 'track' &&
        axios.isAxiosError(e) &&
        e.response?.status === 409
      ) {
        return toast.error('Track with the same name already exists');
      }

      console.log(e);
      toast.error(
        'Something really went wrong, you might want to contact support!'
      );
    }
  }

  const onRenameCancel = useCallback(() => {
    setIsRenameDialogOpen(false);
    setObjectToBeRenamed(null);
  }, []);

  const onRenameSubmit = useCallback(
    (newValue: string) => {
      if (objectToBeRenamed) {
        renameItem(objectToBeRenamed.id, objectToBeRenamed.type, {
          name: newValue,
        });
      }
      setIsRenameDialogOpen(false);
      setObjectToBeRenamed(null);
    },
    // eslint-disable-next-line
    [objectToBeRenamed]
  );

  if (isLoading) return <Loader />;

  return (
    <div className='flex flex-grow gap-x-5'>
      <div className='flex w-3/5 flex-col'>
        <Card title='Filters' className='flex-grow'>
          <div className='flex flex-grow flex-col px-5 pt-4'>
            <div>
              <div className='relative'>
                <MagnifyingGlassIcon
                  className='pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
                <Input
                  autoComplete='off'
                  className='h-12 w-full rounded-md border bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 dark:text-white sm:text-sm'
                  placeholder='Search...'
                  onChange={(event) => {
                    searchParams.set(FiltersEnum.name, event.target.value);
                    setSearchParams(searchParams);
                  }}
                />
              </div>
              <div>
                <div className='!mt-2 grid grid-cols-3 gap-x-4'>
                  <div className='space-y-4'>
                    <div className='font-semibold text-gray-800 dark:text-white'>
                      Projects
                    </div>
                    <Select<IProject>
                      options={projects}
                      placeholder='Filter by submission'
                      selected={
                        submission ? projectsMap[submission] : undefined
                      }
                      setSelected={(v: IProject | undefined) => {
                        if (v && submission === v.id) {
                          searchParams.delete(FiltersEnum.submission);
                        } else {
                          searchParams.set(FiltersEnum.submission, v?.id || '');
                        }
                        setSearchParams(searchParams);
                      }}
                      renderLabel={(v: IProject) => v.name}
                    />
                    <Select<IProject>
                      options={albums}
                      placeholder='Filter by album'
                      selected={album ? albumsMap[album] : undefined}
                      setSelected={(v: IProject | undefined) => {
                        if (v && album === v.id) {
                          searchParams.delete(FiltersEnum.album);
                        } else {
                          searchParams.set(FiltersEnum.album, v?.id || '');
                        }
                        setSearchParams(searchParams);
                      }}
                      renderLabel={(v: IProject) => v.name}
                    />
                    {/* <Select<string | undefined>
                      options={PROJECT_STATUSES}
                      placeholder='Filter by publishing status'
                      selected={albumStatus ? albumStatus : undefined}
                      setSelected={(v: string | undefined) => {
                        if (v && albumStatus === v) {
                          searchParams.delete(FiltersEnum.albumStatus);
                        } else {
                          searchParams.set(FiltersEnum.albumStatus, v || '');
                        }
                        setSearchParams(searchParams);
                      }}
                      renderLabel={(v: string | undefined) => v || ''}
                    /> */}
                  </div>
                  <div className='space-y-4'>
                    <div className='font-semibold text-gray-800 dark:text-white'>
                      People
                    </div>
                    <Select<ITeamMembership>
                      options={artists}
                      placeholder='Filter by artist'
                      selected={artist ? artistsMap[artist] : undefined}
                      setSelected={(v: ITeamMembership | undefined) => {
                        if (v && artist === v.userId) {
                          searchParams.delete(FiltersEnum.artist);
                        } else {
                          searchParams.set(FiltersEnum.artist, v?.userId || '');
                        }
                        setSearchParams(searchParams);
                      }}
                      renderLabel={(v: ITeamMembership) =>
                        `${v.user.firstname} ${v.user.lastname}`
                      }
                    />
                    <Select<IComposer>
                      options={composers}
                      placeholder='Filter by composer'
                      selected={composer ? composersMap[composer] : undefined}
                      setSelected={(v: IComposer | undefined) => {
                        if (v && composer === v.id) {
                          searchParams.delete(FiltersEnum.composer);
                        } else {
                          searchParams.set(FiltersEnum.composer, v?.id || '');
                        }
                        setSearchParams(searchParams);
                      }}
                      renderLabel={(v: IComposer) =>
                        `${v.firstName} ${v.lastName}`
                      }
                    />
                  </div>
                  <div className='space-y-4'>
                    <div className='font-semibold text-gray-800 dark:text-white'>
                      Metadata
                    </div>
                    <Select<number>
                      options={BPMOPtions}
                      placeholder='Filter by bpm'
                      selected={bpm ? +bpm : undefined}
                      setSelected={(v: number | undefined) => {
                        if (v && bpm && +bpm === v) {
                          searchParams.delete(FiltersEnum.bpm);
                        } else {
                          searchParams.set(FiltersEnum.bpm, String(v || ''));
                        }
                        setSearchParams(searchParams);
                      }}
                      renderLabel={(v: number | undefined) => `${v} BPM`}
                    />

                    <Select<string | undefined>
                      options={KEYNOTES}
                      placeholder='Filter by keynote'
                      selected={keynote ? keynote : undefined}
                      setSelected={(v: string | undefined) => {
                        if (v && keynote === v) {
                          searchParams.delete(FiltersEnum.keynote);
                        } else {
                          searchParams.set(FiltersEnum.keynote, v || '');
                        }
                        setSearchParams(searchParams);
                      }}
                      renderLabel={(v) => v || ''}
                    />
                    <Select<string | undefined>
                      options={SCALE_TYPES}
                      placeholder='Filter by scaleType'
                      selected={scaleType ? scaleType : undefined}
                      setSelected={(v: string | undefined) => {
                        if (v && scaleType === v) {
                          searchParams.delete(FiltersEnum.scaleType);
                        } else {
                          searchParams.set(FiltersEnum.scaleType, v || '');
                        }
                        setSearchParams(searchParams);
                      }}
                      renderLabel={(v) => v || ''}
                    />

                    <Select<string | undefined>
                      options={CATALOGUES}
                      placeholder='Filter by catalogue'
                      selected={catalogue ? catalogue : undefined}
                      setSelected={(v: string | undefined) => {
                        if (v && catalogue === v) {
                          searchParams.delete(FiltersEnum.catalogue);
                        } else {
                          searchParams.set(FiltersEnum.catalogue, v || '');
                        }
                        setSearchParams(searchParams);
                      }}
                      renderLabel={(v) => v || ''}
                    />
                  </div>
                </div>
                <div className='flex items-center justify-end pt-2'>
                  <button
                    onClick={resetFilters}
                    className='px-2 text-start text-gray-700 transition-colors hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-500'
                  >
                    Reset filters
                  </button>
                </div>
              </div>
            </div>
            <div className='flex flex-grow flex-col pb-10' id='tags-window'>
              <TagPicker
                tagCategory={activeTagCategory}
                activeTags={curActivaTags}
                allTags={curCategoryTags}
                handleTagSelect={handleTagSelect}
              />
              <div className='grid min-h-[88px] w-full grid-cols-6 gap-3'>
                {tagCategories.map((tagCategory, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setActiveTagCategory(tagCategory);
                      }}
                      className={`animate col-span-1 row-span-1 my-1 flex cursor-pointer items-center rounded-md border-2 border-gray-200 transition-all duration-1000 dark:border-gray-600 dark:hover:bg-slate-600 ${
                        activeTagCategory.name === tagCategory.name
                          ? 'dark:bg-slate-600'
                          : ''
                      }`}
                    >
                      <div className='flex w-full flex-col items-center justify-center px-1 '>
                        <div className='text-md  font-semibold text-gray-600 dark:text-gray-300'>
                          {tagCategory.name}
                        </div>
                        <div className={`text-md ml-1 select-none`}>
                          ({' '}
                          {
                            activeTags.filter(
                              (tag) => tag.type === tagCategory.backendName
                            ).length
                          }{' '}
                          )
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className='flex w-2/5 flex-grow flex-col'>
        <Card
          className='flex-grow'
          title='Tracks'
          //   onClickPlus={openFileDialog}
        >
          <div className='MiniScrollbar relative flex flex-grow flex-col overflow-auto'>
            <div className='absolute w-full'>
              {tracks.map((track) => {
                const curProject = projects.find(
                  (p) => p.id === track.projectId
                );

                return (
                  <TrackListRow
                    track={track}
                    isActive={false}
                    file={track.files?.[0]}
                    key={track.id}
                    showDelete
                    downloadFile={(file) =>
                      downloadFile(
                        file,
                        track,
                        curProject ? curProject : null,
                        activeTeam,
                        `${track.isrc ? `${track.isrc}_` : ''}${track.name}_${
                          activeTeam?.name
                        }${
                          track.metadata.masterStatus === 'mastered'
                            ? ''
                            : `_${track.metadata.masterStatus || 'draft'}`
                        }.wav`
                      )
                    }
                    onRename={() => {
                      setObjectToBeRenamed({ ...track, type: 'track' });
                      setIsRenameDialogOpen(true);
                    }}
                    onDelete={() => {
                      setObjectToBeDeleted({ ...track, type: 'track' });
                      setIsDeleteDialogOpen(true);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </Card>
      </div>
      <DeleteConfirmationDialog
        isLoading={isDeleting}
        isOpen={isDeleteDialogOpen}
        title={`Delete ${
          objectToBeDeleted && capitalize(objectToBeDeleted?.type)
        }`}
        targetName={`${objectToBeDeleted && objectToBeDeleted.name}`}
        close={onDeleteCancel}
        onSubmit={onDeleteSubmit}
      />
      <TextEditDialog
        isOpen={isRenameDialogOpen}
        title={'Rename'}
        initialValue={`${objectToBeRenamed ? objectToBeRenamed?.name : ''}`}
        onCancel={onRenameCancel}
        onSubmit={onRenameSubmit}
      />
    </div>
  );
};
