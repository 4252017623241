import { useEffect, useState } from 'react';
import { ITag } from '../../../../api/services/tag.service';
import { ITrack } from '../../../../api/services/track.service';
import { globalThemeStyles } from '../../../../config/globalStyles';
import InfoTooltip from '../../../shared/InfoTooltip';
import InlineEdit from '../../../shared/InlineEdit';
import InlineTextAreaEdit from '../../../shared/InlineTextareaEdit';
import MaskedInput from '../../../shared/MaskedInput';
import { classNames } from '../../../../utils';
import { usePrevious } from '../../../../hooks/usePrevious';
import axios from 'axios';
import { toast } from 'react-toastify';

export const allKeys = [
  'A',
  'A#',
  'Ab',
  'Atonal',
  'B',
  'Bb',
  'C',
  'C#',
  'Cb',
  'D',
  'D#',
  'Db',
  'E',
  'Eb',
  'F',
  'F#',
  'G',
  'G#',
  'Gb',
];

export function GeneralTabWindow({
  activeTrack,
  activeTags,
  updateTrack,
  canBeModified,
  isTrackValidationErrorsShown,
}: {
  activeTrack: ITrack;
  activeTags: ITag[];
  updateTrack: (id: string, partialTrack: Partial<ITrack>) => Promise<void>;
  canBeModified: boolean;
  isTrackValidationErrorsShown?: boolean;
}) {
  const [trackName, setTrackName] = useState<string>('');
  const [artistName, setArtistName] = useState<string>(''); //activeTrack.artistName

  const [duration, setDuration] = useState<string>(''); //activeTrack.duration
  const [bpm, setBpm] = useState<string>(''); //activeTrack.bpm
  const [trackNumber, setTrackNumber] = useState<string>(''); //activeTrack.trackNumber

  // const [style, setStyle] = useState<string>(""); //activeTrack.style
  const [catalogue, setCatalogue] = useState<string>(''); //activeTrack.scaleType
  const [key, setKey] = useState<string>(''); //activeTrack.key
  const [scaleType, setScaleType] = useState<string>(''); //activeTrack.scaleType

  const [lyrics, setLyrics] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [versionName, setVersionName] = useState<string>(''); //activeTrack.bpm
  const [mainTrackNumber, setMainTrackNumber] = useState<string>(''); //activeTrack.scaleType
  const [isMain, setIsMain] = useState(true); //activeTrack.scaleType

  const isFieldErrorShown = (v: any) => isTrackValidationErrorsShown && !v;

  const prevTrackName = usePrevious(trackName);

  useEffect(() => {
    setTrackName(activeTrack.name ? activeTrack.name : '');
    setArtistName(
      activeTrack.metadata.artistName ? activeTrack.metadata.artistName : ''
    );

    setDuration(
      activeTrack.metadata.duration ? activeTrack.metadata.duration : ''
    );
    setBpm(activeTrack.metadata.bpm ? activeTrack.metadata.bpm : '');
    setTrackNumber(
      activeTrack.metadata.trackNumber ? activeTrack.metadata.trackNumber : ''
    );

    // setStyle(activeTrack.metadata.style ? activeTrack.metadata.style : "");
    setCatalogue(
      activeTrack.metadata.catalogue ? activeTrack.metadata.catalogue : ''
    );
    setKey(activeTrack.metadata.key ? activeTrack.metadata.key : '');
    setScaleType(
      activeTrack.metadata.scaleType ? activeTrack.metadata.scaleType : ''
    );

    setDescription(activeTrack.description);
    setLyrics(activeTrack.metadata.lyrics ? activeTrack.metadata.lyrics : '');

    setVersionName(
      activeTrack.metadata.versionName ? activeTrack.metadata.versionName : ''
    );
    setMainTrackNumber(
      activeTrack.metadata.mainTrackNumber
        ? activeTrack.metadata.mainTrackNumber
        : ''
    );
    setIsMain(activeTrack.metadata.isMain ? true : false);
  }, [activeTrack]);

  async function triggerUpdateWrapper(activeTrackId: string, props: any) {
    // determine final metadata to update
    if (props.metadata) {
      let activeTrackMetadata;
      if (activeTrack.metadata) {
        activeTrackMetadata = activeTrack.metadata;
      }
      props.metadata = {
        ...activeTrackMetadata,
        ...props.metadata,
      };
    }
    try {
      await updateTrack(activeTrackId, { ...props });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        setTrackName(prevTrackName || '');
        toast.error('Track with the same name already exists');
      } else {
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  function updateTrackName(nameValue: string) {
    if (nameValue !== activeTrack.name) {
      triggerUpdateWrapper(activeTrack.id, { name: nameValue });
      setTrackName(nameValue);
    }
  }

  function updateDescription(descriptionValue: string) {
    if (descriptionValue !== activeTrack.description) {
      triggerUpdateWrapper(activeTrack.id, { description: descriptionValue });
      setDescription(descriptionValue);
    }
  }

  function updateArtistName(artistNameValue: string) {
    if (artistNameValue !== activeTrack.metadata.artistName) {
      triggerUpdateWrapper(activeTrack.id, {
        metadata: { artistName: artistNameValue },
      });
      setArtistName(artistNameValue);
    }
  }

  function updateTrackNumber(trackNumberValue: string) {
    if (trackNumberValue !== activeTrack.metadata.trackNumber) {
      triggerUpdateWrapper(activeTrack.id, {
        metadata: { trackNumber: trackNumberValue },
      });
      setTrackNumber(trackNumberValue);
    }
  }

  function updateKey(keyValue: string) {
    if (keyValue !== activeTrack.metadata.key) {
      triggerUpdateWrapper(activeTrack.id, {
        metadata: { key: keyValue },
      });
      setKey(keyValue);
    }
  }

  function updateScaleType(scaleTypeValue: string) {
    if (scaleTypeValue !== activeTrack.metadata.scaleType) {
      triggerUpdateWrapper(activeTrack.id, {
        metadata: { scaleType: scaleTypeValue },
      });
    }
    setScaleType(scaleTypeValue);
  }

  function updateDuration(durationValue: string) {
    if (durationValue !== activeTrack.metadata.duration) {
      triggerUpdateWrapper(activeTrack.id, {
        metadata: { duration: durationValue },
      });
      setDuration(durationValue);
    }
  }

  function updateBpm(bpmValue: string) {
    if (bpmValue !== activeTrack.metadata.bpm) {
      triggerUpdateWrapper(activeTrack.id, {
        metadata: { bpm: bpmValue },
      });
      setBpm(bpmValue);
    }
  }

  function updateCatalogue(catalogueValue: string) {
    if (catalogueValue !== activeTrack.metadata.catalogue) {
      triggerUpdateWrapper(activeTrack.id, {
        metadata: { catalogue: catalogueValue },
      });
    }
    setCatalogue(catalogueValue);
  }

  function updateLyrics(lyricsValue: string) {
    if (lyricsValue !== activeTrack.metadata.lyrics) {
      triggerUpdateWrapper(activeTrack.id, {
        metadata: { lyrics: lyricsValue },
      });
      setLyrics(lyricsValue);
    }
  }

  // relevant if not main track
  function updateMainTrackNumber(mainTrackNumberValue: string) {
    if (mainTrackNumberValue !== activeTrack.metadata.mainTrackNumber) {
      triggerUpdateWrapper(activeTrack.id, {
        metadata: { mainTrackNumber: mainTrackNumberValue },
      });
      setMainTrackNumber(mainTrackNumberValue);
    }
  }

  function updateVersionName(versionNameValue: string) {
    if (versionNameValue !== activeTrack.metadata.versionName) {
      triggerUpdateWrapper(activeTrack.id, {
        metadata: { versionName: versionNameValue },
      });
      setVersionName(versionNameValue);
    }
  }

  function updateIsMain(isMainValue: boolean) {
    triggerUpdateWrapper(activeTrack.id, {
      metadata: {
        isMain: isMainValue,
        versionName: isMain ? '' : activeTrack.metadata.versionName || '',
        mainTrackNumber: isMain
          ? ''
          : activeTrack.metadata.mainTrackNumber || '',
      },
    });
    if (isMainValue) {
      setMainTrackNumber('');
      setVersionName('');
    }
    setIsMain(isMainValue);
  }

  // FIXME: moved from tags to metadata, can be changed later
  // useEffect(() => {
  //   // track general tags
  //   activeTags.forEach((tag) => {
  //     switch (tag.type) {
  //       case "style":
  //         setStyle(tag.name);
  //         break;
  //       case "key":
  //         setKey(tag.name);
  //         break;
  //       case "scaleType":
  //         setScaleType(tag.name);
  //         break;
  //       case "catalogue":
  //         setCatalogue(tag.name);
  //         break;
  //     }
  //   });
  // }, [activeTags]);

  return (
    <>
      <div className='grid w-full grid-cols-4 gap-x-4 rounded-b-2xl'>
        <div className='col-span-3 md:col-span-1'>
          <label
            className={classNames(
              `flex items-center text-sm font-medium ${
                isFieldErrorShown(trackName) ? 'text-red-500' : ''
              }`
            )}
          >
            Original Track Name *&nbsp;
            <InfoTooltip hint='If you upload a version (e.g. "No Drums Mix"), then uncheck "Main Track".' />
          </label>
          <InlineEdit
            id='track-name'
            key='trackName'
            isDisabled={!canBeModified}
            value={trackName}
            setValue={(e) => updateTrackName(e)}
            className={`mt-3 block w-full shadow-md sm:text-sm ${globalThemeStyles.inputbox}`}
          />
        </div>
        <div className='col-span-3 md:col-span-1'>
          <label className='flex items-center text-sm font-medium'>
            Artist Name&nbsp;
            <InfoTooltip
              hint={
                <>
                  <div>
                    Please only use this if your artist name is registered as an
                    Alias with your PRO.
                  </div>
                  <div>
                    Otherwise we will have to release under your real name,
                    which follows in the "Composer" section.
                  </div>
                </>
              }
            />
          </label>
          <InlineEdit
            key='artistName'
            isDisabled={!canBeModified}
            value={artistName}
            setValue={(e) => updateArtistName(e)}
            className={`mt-3 block w-full shadow-md sm:text-sm ${globalThemeStyles.inputbox}`}
          />
        </div>
        <div className='col-span-3 gap-2 md:col-span-1'>
          <label
            className={classNames(
              `flex items-center text-sm font-medium ${
                isFieldErrorShown(trackNumber) ? 'text-red-500' : ''
              }`
            )}
          >
            Track Nr *&nbsp;
            <InfoTooltip hint='If this track is not the original but a version, uncheck here.' />
          </label>
          <div className='mt-3 flex items-center gap-x-2'>
            <InlineEdit
              key='trackNumber'
              pattern='[0-9]*'
              isDisabled={!canBeModified}
              value={trackNumber}
              setValue={(e) => updateTrackNumber(e)}
              className={`input-no-arrows block w-10 !px-0 text-center shadow-md sm:text-sm ${globalThemeStyles.inputbox}`}
              type='number'
              min={1}
              max={99}
            />
            <div className='flex items-center'>
              <input
                checked={isMain}
                disabled={!canBeModified}
                onClick={(e) => updateIsMain(!isMain)}
                onChange={() => null}
                type='checkbox'
                className={`h-5 w-5 rounded text-indigo-600 shadow-md focus:ring-0 dark:bg-slate-800 dark:text-indigo-700 ${globalThemeStyles.inputbox}`}
              />

              <div className='mx-2 flex items-center text-sm 2xl:mr-6'>
                Main Track{' '}
                {!isMain && (
                  <>
                    Nr&nbsp;
                    <InfoTooltip hint='The track number that your mix refers to.' />{' '}
                  </>
                )}
              </div>
              {!isMain && (
                <InlineEdit
                  type='number'
                  key='mainTrackNumber'
                  isDisabled={!canBeModified}
                  value={mainTrackNumber}
                  setValue={updateMainTrackNumber}
                  className={`input-no-arrows block w-10 !px-0 text-center shadow-md sm:text-sm ${globalThemeStyles.inputbox}`}
                  min={1}
                  max={99}
                />
              )}
            </div>
          </div>
        </div>
        <div className='col-span-3 md:col-span-1'>
          {!isMain && (
            <>
              <label className='flex items-center text-sm font-medium'>
                Version Name
              </label>
              <InlineEdit
                key='input5'
                isDisabled={!canBeModified}
                value={versionName}
                setValue={(e) => updateVersionName(e)}
                className={`mt-3 block w-full shadow-md sm:text-sm ${globalThemeStyles.inputbox}`}
              />
            </>
          )}
        </div>

        <div className='col-span-4 md:col-span-1'>
          <div className='grid grid-cols-2 gap-4'>
            <div>
              <label
                className={classNames(
                  `flex items-center text-sm font-medium ${
                    isFieldErrorShown(key) ? 'text-red-500' : ''
                  }`
                )}
              >
                Keynote *
              </label>
              <select
                disabled={!canBeModified}
                value={key}
                onChange={(e) => updateKey(e.target.value)}
                className={`mt-3 block w-full p-2.5 text-sm shadow-md disabled:cursor-not-allowed ${globalThemeStyles.inputbox}`}
                data-testid='key-select'
              >
                <option value=''>Not selected</option>
                {allKeys.map((key) => (
                  <option value={key} key={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                className={classNames(
                  `flex items-center text-sm font-medium ${
                    isFieldErrorShown(scaleType) ? 'text-red-500' : ''
                  }`
                )}
              >
                Scale Type *
              </label>
              <select
                disabled={!canBeModified}
                value={scaleType}
                onChange={(e) => updateScaleType(e.target.value)}
                className={`mt-3 block w-full p-2.5 text-sm shadow-md disabled:cursor-not-allowed ${globalThemeStyles.inputbox}`}
                data-testid='scale-type-select'
              >
                <option value=''>Not selected</option>
                <option value='Major'>Major</option>
                <option value='Minor'>Minor</option>
              </select>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-2 gap-4'>
          <div className='col-span-4 md:col-span-1'>
            <label
              className={classNames(
                `flex items-center text-sm font-medium ${
                  isFieldErrorShown(duration) ? 'text-red-500' : ''
                }`
              )}
            >
              Duration *
            </label>
            <MaskedInput
              value={duration}
              className={`mt-3 block w-full p-2.5 text-sm shadow-md ${globalThemeStyles.inputbox}`}
              onChange={updateDuration}
              placeholder='mm:ss'
              disabled={!canBeModified}
            />
          </div>
          <div className='col-span-4 md:col-span-1'>
            <label
              className={classNames(
                `flex items-center text-sm font-medium ${
                  isFieldErrorShown(bpm) ? 'text-red-500' : ''
                }`
              )}
            >
              BPM *
            </label>
            <InlineEdit
              key='input7'
              isDisabled={!canBeModified}
              type='number'
              value={bpm}
              pattern='[0-9]*'
              setValue={(e) => updateBpm(e)}
              className={`mt-3 block w-full p-2.5 text-sm shadow-md ${globalThemeStyles.inputbox}`}
            />
          </div>
        </div>

        <div className='col-span-3 md:col-span-1'>
          <label
            className={classNames(
              `flex items-center text-sm font-medium ${
                isFieldErrorShown(catalogue) ? 'text-red-500' : ''
              }`
            )}
          >
            Catalogue *
          </label>
          <select
            disabled={!canBeModified}
            value={catalogue}
            onChange={(e) => updateCatalogue(e.target.value)}
            className={`mt-3 block w-full p-2.5 text-sm shadow-md disabled:cursor-not-allowed ${globalThemeStyles.inputbox}`}
            data-testid='catalogue-select'
          >
            <option value=''>Not selected</option>
            <option value='Soundtrack'>Soundtrack</option>
            <option value='Trailer Music'>Trailer Music</option>
            <option value='Trailer Music;Soundtrack'>
              Trailer Music and Soundtrack
            </option>
          </select>
        </div>
        <div className='col-span-2'>
          <label className='flex items-center text-sm font-medium'>
            Description&nbsp;
            <InfoTooltip hint='Max. 1000 characters.' />{' '}
          </label>
          <div className='mt-3'>
            <InlineTextAreaEdit
              testid='description-input'
              key='input8'
              isDisabled={!canBeModified}
              rows={3}
              className={`block min-h-full w-full shadow-md sm:text-sm ${globalThemeStyles.inputbox}`}
              value={description}
              setValue={(e) => updateDescription(e)}
            />
          </div>
        </div>
        <div className='col-span-2'>
          <div className=''>
            <label className='flex items-center text-sm font-medium'>
              Lyrics
            </label>
            <div className='mt-3'>
              <InlineTextAreaEdit
                testid='lyrics-input'
                key='input9'
                isDisabled={!canBeModified}
                rows={3}
                className={`block min-h-full w-full shadow-md sm:text-sm ${globalThemeStyles.inputbox}`}
                value={lyrics}
                setValue={(e) => updateLyrics(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
